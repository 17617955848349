import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { getEnv } from 'mobx-state-tree';
import { useTheme } from 'react-jss';
import { toast } from 'react-toastify';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { convertOptionsToStrings, getOptionsByNameId } from '@shared/helpers/form';
import { OptionType } from '@shared/components/SelectDropdown';
import { ToastMessage } from '@shared/components/Toast';
import { FormError } from '@shared/components/FormError';
import { useUsersGridUIStore, useUserUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';

import { IUserStore } from 'Users/stores';
import { messages } from 'Users/users.messages';

import { useStyles } from './UserModal.styles';

export const COMMON_SELECT_PROPS = {
  isSearchable: false,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: false,
  closeMenuOnSelect: true,
  components: { Option: SelectOption },
}

export type UserModalProps = {
  isOpen: boolean,
  onRequestClose: () => void,
}

export class UserFormValues {
  email = '';
  firstName = '';
  lastName = '';
  department = '';
  roleId = { value: '', label: '' };
  groups = [] as OptionType[];
  procedures = [] as OptionType[];
}

export const UserModal: FC<UserModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [userData, setUserData] = useState<IUserStore | null>(null);

  const usersGridUIStore = useUsersGridUIStore();
  const userUIStore = useUserUIStore();
  const { filtersOptions } = getEnv<IStoresEnv>(usersGridUIStore);

  const assignableRolesOptions = filtersOptions.getAssignableRolesOptions;
  const groupsOptions = filtersOptions.getGroupsOptions;
  const proceduresOptions = filtersOptions.getProceduresOptions;

  const isEditMode = !!userUIStore.userToEditId;

  useEffect(() => {
    const getUserData = async () => {
      if (isEditMode) {
        const result = await userUIStore.loadUser({ id: userUIStore.userToEditId });

        if (result.success) {
          setUserData(result.data);
        }
      }
    }

    getUserData();
  }, [isEditMode]);

  const onDeleteUser = () => {
    userUIStore.toggleDeleteConfirmationModalOpened(true);
  }

  const onSubmit = async (values: UserFormValues) => {
    const { email, firstName, lastName, department, ...rest } = values;

    const computedValues = {
      email,
      firstName,
      lastName,
      department: department.trim(),
      roleId: rest.roleId ? rest.roleId.value : '',
      groupIds: rest.groups ? convertOptionsToStrings(rest.groups) : [],
      procedureIds: rest.procedures ? convertOptionsToStrings(rest.procedures) : [],
    }

    const result = isEditMode
      ? await userUIStore.editUser({ id: userUIStore.userToEditId, ...computedValues })
      : await usersGridUIStore.inviteUser.sendInviteUserRequest(computedValues);

    if (result.success) {
      usersGridUIStore.setParams({
        page: 0,
      });

      usersGridUIStore.load();
      filtersOptions.loadFilterOptions();

      onRequestClose();

      toast.success(
        <ToastMessage
          message={messages['userModal.submit.toast.success'](isEditMode)}
          type='success'
        />
      );
    }
  }

  const initialValues = (isEditMode && userData) ? {
    email: userData.email || '',
    firstName: userData.firstName || '',
    lastName: userData.lastName || '',
    department: userData.department || '',
    roleId: { value: userData.roles[0]?.id, label: userData.roles[0]?.name },
    procedures: getOptionsByNameId(userData.procedures,proceduresOptions),
    groups: getOptionsByNameId(userData.groups,groupsOptions),
  } : new UserFormValues();

  const firstName = userData?.firstName || '';
  const lastName = userData?.lastName || '';

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <h2 className={styles.h2}>
        {isEditMode ? `${firstName} ${lastName}` : messages['userModal.newUser']}
      </h2>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {(form: FormikProps<UserFormValues>) => {
          const fields = usersGridUIStore.inviteUser.getInviteUserFields;
          const storeErrors = userUIStore.userUIData.errors || fields.errors;

          const formErrors: Record<string, any> = form.errors;
          const errors = Object.keys(formErrors).length
            ? Object.keys(formErrors)
              .map((key: string) => formErrors[key].value ? formErrors[key].value : formErrors[key])
            : storeErrors;

          return (
            <Form className={styles.form} autoComplete='off' noValidate>
              <Observer>
                {() => (
                  <>
                    {errors?.length && (
                      <div className={styles.errors}>
                        {errors.map((text: string) => (
                          <FormError className={styles.error} key={text} text={text} />
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Observer>
              <div className={styles.field}>
                <FormikTextInput
                  schema={valuesSchema.email}
                  cleanable
                />
              </div>
              <div className={styles.fieldWrapper}>
                <div className={classNames(styles.field, styles.halfWidth)}>
                  <FormikTextInput schema={valuesSchema.firstName} />
                </div>
                <div className={classNames(styles.field, styles.halfWidth)}>
                  <FormikTextInput schema={valuesSchema.lastName} />
                </div>
              </div>
              <div className={styles.fieldWrapper}>
                <div className={classNames(styles.field, styles.halfWidth)}>
                  <FormikTextInput schema={valuesSchema.department} />
                </div>
                <div className={classNames(styles.field, styles.halfWidth)}>
                  <FormikSelectDropdown
                    schema={valuesSchema.roleId}
                    className={styles.dropdown}
                    {...COMMON_SELECT_PROPS}
                    options={assignableRolesOptions}
                  />
                </div>
              </div>
              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.groups}
                  className={styles.dropdown}
                  {...COMMON_SELECT_PROPS}
                  isMulti
                  closeMenuOnSelect={false}
                  options={groupsOptions}
                />
              </div>
              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.procedures}
                  className={styles.dropdown}
                  {...COMMON_SELECT_PROPS}
                  isMulti
                  closeMenuOnSelect={false}
                  options={proceduresOptions}
                />
              </div>
              <div className={classNames(styles.buttons, { [styles.end]: !isEditMode })}>
                {
                  isEditMode && (
                    <Button
                      onClick={onDeleteUser}
                      color="secondary"
                    >
                      {messages['userModal.buttons.delete']}
                    </Button>
                  )
                }
                <div>
                  <Button
                    onClick={onRequestClose}
                    className={styles.button} color="secondary"
                  >
                    {messages['userModal.buttons.cancel']}
                  </Button>
                  <Button
                    className={styles.button}
                    type="submit"
                  >
                    {messages['userModal.buttons.submit'](isEditMode)}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
