import { HttpStatusCode } from '@ace/core';

// export const BASE_URL = 'http://testing-ct-1936349679.eu-south-1.elb.amazonaws.com';
export const BASE_URL = 'https://weavr-backend.txtgroup.com';
// export const BASE_URL = 'https://app-weavrmanager-dev-001.azurewebsites.net';
// export const BASE_URL = 'http://staging-c1-1945748706.eu-central-1.elb.amazonaws.com';
// export const BASE_URL = 'http://localhost:8001';
export const CLIENT_ID = 'ro.weavrManagerUI';
export const SECRET = 'secret';
export const SCOPE = 'offline_access openid profile identity analytic collaboration content manageruiagg server';
export const TOKEN_TYPE = 'Bearer';
export const GRANT_TYPE = 'refresh_token';

export const DEFAULT_API_ERROR_MESSAGES: Record<string, any> = {
  [HttpStatusCode.UNAUTHORIZED]: 'You are not authorized to perform this action',
  [HttpStatusCode.FORBIDDEN]: 'You are forbidden to perform this action',
  [HttpStatusCode.NOT_FOUND]: 'The resource was not found',
  [HttpStatusCode.REQUEST_TIMEOUT]: 'The request timed out',
  [HttpStatusCode.GATEWAY_TIMEOUT]: 'The request timed out',
  [HttpStatusCode.BAD_REQUEST]: 'Bad request',
  [HttpStatusCode.INTERNAL_SERVER_ERROR]: 'Internal server error',
}
