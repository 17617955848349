import { IBaseItem } from '@shared/stores';
import { ISelectOption } from './ISelectOption';

export const getOptionsByStrings = (value: string[], options: ISelectOption[], alternateOptions:IBaseItem[]|undefined=undefined) => {
  if (!options || !options.length) {
    return [];
  }

  return value.map(value => getOptionByString(value, options,alternateOptions));
}

export const getOptionByString = (value: string, options: ISelectOption[],alternateOptions:IBaseItem[]|undefined=undefined) => ({
  value,
  label: options.find(option => option.value === value)?.label || alternateOptions?.find(opt => opt.id=== value)?.name,
  disabled: !options.find(option => option.value === value)
});
