import { Instance, SnapshotIn, types, flow, getEnv, applySnapshot } from 'mobx-state-tree';
import { IStoresEnv } from '@core/storesEnv';

import { getOptionsByNameId } from '@shared/helpers/form';
import {
  performGetProcedureVersions, performGetProcedureVersionPlatforms,
} from '../services';

export const analyticsFiltersOptionsInitialState = {
  procedureVersions: [],
  procedureVersionPlatforms: [],
}

export const AnalyticsFiltersOptionsStoreInferred = types
  .model('AnalyticsFiltersOptionsStoreInferred',
    {
      procedureVersions: types.optional(types.array(types.model({
        id: types.string,
        name: types.string,
      })), []),
      procedureVersionPlatforms: types.optional(types.array(types.model({
        id: types.string,
        name: types.string,
        procedureVersionId: types.string
      })), []),
    })
  .views(self => ({
    get getProcedureVersionsOptions() {
      return getOptionsByNameId(self.procedureVersions);
    },
    getProcedureVersionPlatformsOptions(filter:string) {
      return getOptionsByNameId(self.procedureVersionPlatforms.filter(el=>el.procedureVersionId==filter))
    }
  }))
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const loadProcedureVersions = flow(function* (payload) {
      const result = yield performGetProcedureVersions(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.procedureVersions = result.data;
    });

    const loadProcedureVersionPlatforms = flow(function* (payload) {
      const result = yield performGetProcedureVersionPlatforms(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.procedureVersionPlatforms = result.data;
    });

    const resetFilters = () => {
      applySnapshot(self, analyticsFiltersOptionsInitialState);
    }

    const loadFilterOptions = flow(function* (payload) {
      yield Promise.all([
        loadProcedureVersions(payload),
        loadProcedureVersionPlatforms(payload),
      ])
    });

    return {
      loadFilterOptions,
      resetFilters,
    }
  })

type AnalyticsFiltersOptionsStoreFactoryType = typeof AnalyticsFiltersOptionsStoreInferred;
interface IAnalyticsFiltersOptionsStoreFactory extends AnalyticsFiltersOptionsStoreFactoryType {}
export const AnalyticsFiltersOptionsStore: IAnalyticsFiltersOptionsStoreFactory = AnalyticsFiltersOptionsStoreInferred;
export interface IAnalyticsFiltersOptionsStore extends Instance<IAnalyticsFiltersOptionsStoreFactory> {}
export interface IAnalyticsFiltersOptionsStoreSnapshotIn extends SnapshotIn<IAnalyticsFiltersOptionsStore> {}
